import ProductPdp from 'adn_js/javascript/corp/controllers/product_pdp_controller';
import { updateQueryStringParameter } from 'adn_js/javascript/backoffice/helpers/app_helper';

export default class extends ProductPdp {
  connect() {
    super.connect();
    this.initReadMore();
  }
  initReadMore() {
    const $elements = $(this.element);
    const descArea = $elements.find('.top-part');
    const imagesHeight = $elements.find('.images-container').height();
    const descHeight = descArea.height();

    if(descHeight > imagesHeight) {
      descArea.height(`${imagesHeight - 40}px`);
      const readMoreLink = $elements.find('.read-more');
      readMoreLink.show();
      readMoreLink.on('click', () => {
        let newHeight = descHeight + 20;
        if (readMoreLink.hasClass('expanded')) {
          newHeight = imagesHeight - 40;
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
        readMoreLink.toggleClass('expanded');
        descArea.animate({
          height: newHeight
        }, 500);
      });
    }
  }

  changeQuantityByButton() {
    const url = $(this.addToOrderButtonTarget).attr('href');
    const qtyVal = $(this.element).find("input[name='quantity']").val();
    const updatedUrl = updateQueryStringParameter(url, 'quantity', qtyVal);
    $(this.addToOrderButtonTarget).attr('href', updatedUrl);
  }
}
