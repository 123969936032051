import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form" ];

  clearSearch(e) {
    e.preventDefault();
    e.stopPropagation();
    $(this.element).find('#keywords').val('');
    this.submitForm();
  }

  search(e) {
    if (e.target.type === 'radio') {
      $(this.formTarget).find("select").removeAttr('name');
    } else {
      $(this.formTarget).find("input[type='radio']").removeAttr('name');
    }
    this.submitForm();
  }

  submitForm() {
    const form = $(this.element).find('form')[0];
    // we need to fire submit in this way since we are using data remote:true for the form.
    // Other ways of triggering submit is reloading the whole page
    $.rails.fire(form, 'submit');
  }
}