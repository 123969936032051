import HomeController from "adn_js/javascript/corp/controllers/home_controller";

export default class extends HomeController {
  connect() {
    this.initScrollAnimations();

    // Password reset modal
    if (
      window.location.pathname === "/" &&
      window.location.hash == "#reset-password-successful"
    ) {
      $("#reset-password-modal").modal("show");
    }
  }
};
